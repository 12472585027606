import { SVGProps, Ref, forwardRef } from 'react'

const SvgPlay = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.839 12.284V3.562c0-1.616 1.818-2.565 3.143-1.641L19.5 10.643a2 2 0 0 1 0 3.282L6.983 22.648c-1.326.924-3.144-.025-3.144-1.641v-8.723Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgPlay)
export default ForwardRef
